$cell-color: #003055;
$border-base-color: #051943;
$border-base-hover-color: #248092;
$border-active-color: #e2cdb1; // TODO maybe make darker
$border-active-hover-color: #248092;
$border-dangling-color: #584e82;
$main-background-color: #051943;
$galaxy-center-text-incorrect-color: #ff7623;
$galaxy-center-text-base-color: white;
$asymmetric-center-circle-color: #9f6666;
$celebration-text-color: white;
$ui-text-font-family: sans-serif;

rect {
  &.cell {
    fill: $cell-color;

    &.correct {
      fill: #bfb;
    }

    &.missing-center {
      fill: #fbb;
    }
  }

  &.border {
    &.active {
      fill: $border-active-color;

      &.dangling {
        fill: $border-dangling-color;
      }
    }
  }
}

.border-group {
  cursor: pointer;
  fill: transparent;
  touch-action: manipulation;

  &:hover {
    > .border {
      fill: $border-base-hover-color;

      &.active {
        fill: $border-active-hover-color;
      }
    }
  }
}

.corner {
  fill: $border-base-color;

  &.active {
    fill: $border-active-color;
  }
}

svg.board {
  background-color: $border-base-color;
}

body {
  background-color: $main-background-color;
  margin: 0;
  width: 100vw;
  height: 100vh;
}

.main {
  width: 100%;
  height: 100%;
}

text.galaxy-center {
  font-family: monospace;
  font-size: 32px;
  font-weight: bold;
  fill: $galaxy-center-text-base-color;

  &.incorrect-size {
    fill: $galaxy-center-text-incorrect-color;
  }
}

circle.asymmetric-center {
  fill: $asymmetric-center-circle-color;
}

.board-control-buttons {
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;

  @media (orientation: landscape) {
    justify-content: center;
  }
}

.board-control-button {
  font-family: $ui-text-font-family;
  flex-grow: 1;
  max-height: 64px;
  font-size: 24px;
}

.board-container {
  display: flex;
  width: 100%;
  height: 100%;
  @media (orientation: landscape) {
    flex-direction: row;
  }
  @media (orientation: portrait) {
    flex-direction: column;
  }
}

.celebration-text {
  color: $celebration-text-color;
  font-family: $ui-text-font-family;
  font-size: 24px;
  text-align: center;
}
