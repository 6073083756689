rect.cell {
  fill: #003055;
}
rect.cell.correct {
  fill: #bfb;
}
rect.cell.missing-center {
  fill: #fbb;
}
rect.border.active {
  fill: #e2cdb1;
}
rect.border.active.dangling {
  fill: #584e82;
}

.border-group {
  cursor: pointer;
  fill: transparent;
  touch-action: manipulation;
}
.border-group:hover > .border {
  fill: #248092;
}
.border-group:hover > .border.active {
  fill: #248092;
}

.corner {
  fill: #051943;
}
.corner.active {
  fill: #e2cdb1;
}

svg.board {
  background-color: #051943;
}

body {
  background-color: #051943;
  margin: 0;
  width: 100vw;
  height: 100vh;
}

.main {
  width: 100%;
  height: 100%;
}

text.galaxy-center {
  font-family: monospace;
  font-size: 32px;
  font-weight: bold;
  fill: white;
}
text.galaxy-center.incorrect-size {
  fill: #ff7623;
}

circle.asymmetric-center {
  fill: #9f6666;
}

.board-control-buttons {
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
}
@media (orientation: landscape) {
  .board-control-buttons {
    justify-content: center;
  }
}

.board-control-button {
  font-family: sans-serif;
  flex-grow: 1;
  max-height: 64px;
  font-size: 24px;
}

.board-container {
  display: flex;
  width: 100%;
  height: 100%;
}
@media (orientation: landscape) {
  .board-container {
    flex-direction: row;
  }
}
@media (orientation: portrait) {
  .board-container {
    flex-direction: column;
  }
}

.celebration-text {
  color: white;
  font-family: sans-serif;
  font-size: 24px;
  text-align: center;
}
/*# sourceMappingURL=index.57678093.css.map */
